// import React from 'react'
// import Icon from '../Icon'

export const marketUrl = '/'
export const navs = (t, isMobile = false) => {
  const defaultNavs = [
    {
      label: t('header.home'),
      url: '/home',
      key: '/home',
    },
    {
      label: t('header.media'),
      url: '/media',
      key: '/media',
      children: [
        {
          label: t('header.media_list.0'),
          url: '/information',
          key: '/information',
        },
        {
          label: t('header.media_list.1'),
          url: '/news',
          key: '/news',
        },
        {
          label: t('header.media_list.2'),
          url: '/bulletin',
          key: '/bulletin',
        },
      ],
    },
    {
      label: t('header.wallet'),
      url: '/wallet',
      key: '/wallet',
      // disabled: true, //当没有地址时，要把disabled设置为true，移动端组件需要
    },
    {
      label: t('header.activity'),
      url: '/activity',
      key: '/activity',
      // disabled: true,
    },
    {
      label: t('header.video'),
      url: '/video',
      key: '/video',
      // disabled: true,
    },
    {
      label: t('header.service'),
      url: '',
      link_url: 'https://bs.parapack.ai/#/project/application',
      key: 'https://bs.parapack.ai/#/project/application',
    },
    {
      label: t('header.download'),
      url: '/download',
      key: '/download',
    },
  ]
  if (isMobile) {
    // const BuyAac = {}
    return [...defaultNavs]
  }

  return defaultNavs
}
