const routes = () => [
  // path: '/home/:join?/:invite_code?',携带参数方式
  /**
   * @descrition 携带参数方式
   * @params {String} 当前参数
   * @identification {?} ?为参数可选
   * @hideFooter hideFooter:true 不显示Footer
    * @path 路由
        @icon 图标
        @title 菜单名称 支持语言切换
        @component 组件地址，当它是group入口（二级/三级菜单入口）时，设置为空
        @exact 是否匹配子路由
        @link 有表示链接第三方链接
        @children: 二级菜单
   **/
  {
    path: '/home',
    component: () => import('../pages/home'),
    exact: true,
  },
  {
    path: '/media',
    component: () => import('../pages/media/information'),
    exact: true,
    children: [
      {
        path: '/information',
        component: () => import('../pages/media/information'),
        exact: true,
      },
      {
        path: '/news',
        component: () => import('../pages/media/news'),
        exact: true,
      },
      {
        path: '/bulletin',
        component: () => import('../pages/media/bulletin'),
        exact: true,
      },
      {
        path: '/media/:id',
        component: () => import('../pages/media/details'),
        exact: true,
      },
    ],
  },
  {
    path: '/wallet',
    component: () => import('../pages/wallet'),
    exact: true,
  },
  {
    path: '/activity',
    component: () => import('../pages/activity'),
    exact: true,
    children: [
      {
        path: '/activity/details/:id',
        component: () => import('../pages/activity/details'),
        exact: true,
      },
      {
        path: '/activity/collection/:id',
        component: () => import('../pages/activity/collection'),
        exact: true,
      },
    ],
  },
  {
    path: '/video/:id?',
    component: () => import('../pages/video'),
    exact: true,
    hideFooter: true,
  },
  {
    path: '/download',
    component: () => import('../pages/download'),
    exact: true,
  },
]
export default routes
