import React, { useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import Icon from '../Icon'
import './index.less'
import { useTranslation } from 'react-i18next'
import { imgUrl } from '../../utils/staticUrl'
import Button from '../Button'
const PassFooter = () => {
  const { t } = useTranslation()
  const { lng } = useAppSelector((store) => store.global)
  const getUrl = (url, type) => {
    return `${url}${lng}${type}`
  }
  const footerMenu = [
    {
      title: t('header.wallet'),
      list: [
        {
          name: t('footer.app_store'),
          url: 'https://bs.parapack.ai/#/project/application',
        },
        {
          name: t('footer.token_submit'),
          url: 'https://bs.parapack.ai/#/project/application',
        },
      ],
    },
    {
      title: t('header.activity'),
      list: [
        {
          name: t('footer.activity_tutorial'),
          url: '',
        },
        {
          name: t('footer.activity_release'),
          url: 'https://bs.parapack.ai/#/activity/release',
        },
        {
          name: t('footer.joint_airdrop'),
          url: '',
        },
      ],
    },
    {
      title: t('header.video'),
      list: [
        {
          name: t('footer.video_tutorial'),
          url: '',
        },
        {
          name: t('footer.creation_center'),
          url: '',
        },
        {
          name: t('footer.kol_entry'),
          url: '',
        },
        {
          name: t('footer.community_entry'),
          url: '',
        },
      ],
    },
    {
      title: t('footer.support'),
      list: [
        {
          name: t('footer.about_ParaPack'),
          url: '',
        },
        {
          name: t('footer.agent_apply'),
          url: 'https://bs.parapack.ai/#/activity/release',
        },
        {
          name: t('footer.service_agreement'),
          // url: 'https://insex.parapack.net/ParaPack_En.pdf',
          url: getUrl('https://insex.parapack.net/ParaPack_', '.pdf'),
        },
        {
          name: t('footer.privacy_policy'),
          // url: 'https://insex.parapack.ai/ServiceTerms_el_GR.html',
          url: getUrl('https://insex.parapack.ai/ServiceTerms_', '.html'),
        },
      ],
    },
  ]
  const shareList = [
    {
      icon: imgUrl('common/footer_share_1.png'),
      url: 'mailto:service@parapack.ai',
    },
    {
      icon: imgUrl('common/footer_share_2.png'),
      url: 'https://twitter.com/parapack_pp',
    },
    {
      icon: imgUrl('common/footer_share_3.png'),
      url: 'https://t.me/parapack_official_community',
    },
    {
      icon: imgUrl('common/footer_share_4.png'),
      url: 'https://discord.gg/UCSU2nmtfK',
    },
  ]

  useEffect(() => {
    console.log(lng)
  }, [lng])
  return (
    <div id="pass_footer">
      <div className="content">
        <div className="content_left">
          <div className="logo">
            <img src={imgUrl('common/logo.png')} />
          </div>
          <div className="introduce">{t('footer.focus_web3')}</div>
          <div className="button_group f-start">
            <a href={window.location.origin + '/#/download'}>
              <Button>{t('footer.experience_app')}</Button>
            </a>
            <a href={getUrl('https://insex.parapack.net/ParaPack_', '.pdf')} target={'blank'}>
              <Button>{t('footer.view_whitepaper')}</Button>
            </a>
          </div>
          <div className="share_list f-start">
            {shareList.map((item, index) => (
              <a href={item.url} target={'blank'} key={index}>
                <img src={item.icon} />
              </a>
            ))}
          </div>
        </div>
        <div className="content_right">
          {footerMenu &&
            footerMenu.map((item, index) => (
              <div className="footer_menu" key={index}>
                <div className="menu_title">{item.title}</div>
                {item.list &&
                  item.list.map((it, id) => (
                    <div className="menu_list" key={id}>
                      <div className="list">
                        {it.url ? (
                          <a href={it.url} target={'blank'}>
                            <div className="menu_name">{it.name}</div>
                          </a>
                        ) : (
                          <div className="menu_name_disabled">{it.name}</div>
                        )}
                        {it.children && <Icon type="icon-arrow_down_w" />}
                      </div>
                      <div className="list_children">
                        {it.children &&
                          it.children.map((i, d) => (
                            <div className="child" key={d}>
                              {i.url ? (
                                <a href={i.url} target={'blank'}>
                                  <div className="children">{i.name}</div>
                                </a>
                              ) : (
                                <div className="children">{i.name}</div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default PassFooter
